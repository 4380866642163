const config = {
  apiGateway: {
    REGION: 'ap-southeast-2',
    INTERNAL_API_URL: 'https://ft44e3ngj2.execute-api.ap-southeast-2.amazonaws.com/prod',
    TEAM_API_URL: 'https://7a6592ogqc.execute-api.ap-southeast-2.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'taxonomy-teams-1.10.2',
    HOSTNAME: 'https://teams-service.ausgrid.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.ausgrid.forwoodsafety.com',
    WEBSOCKET: 'wss://m658sl3l7l.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
};

export default config;
